<template>
    <div class="our-branches container-l bgpranch py-32">
        <div class="our-branches-container">
            <div class="our-branches-head w-full lg:w-1/3">
                <figure class="grafic">
                    <img
                        src="@/assets/images/Branches.svg"
                        alt=""
                    />
                </figure>
                <h2 class="title">{{ $t("headings.ourBranches") }}</h2>
            </div>
            <div class="our-branches-content w-full lg:w-2/3">
                <div class="branch">
                    <div class="branch-details">
                        <h3 class="branch-title">Egypt</h3>
                        <div class="branch-detail branch-location">
                            <font-awesome-icon
                                class="icon"
                                :icon="['fas', 'map-marker-alt']"
                                size="2x"
                            />
                            <p>El-maktaba El-aama st., - 4th district - New Damietta</p>
                        </div>
                        <div class="branch-detail branch-phone">
                            <font-awesome-icon
                                class="icon"
                                :icon="['fas', 'mobile-alt']"
                                size="2x"
                            />
                            <div>
                                <a href="tel:+201093895621">+201093895621</a>
                                <!-- <br />
                                <a href="tel:+002246652566">002246652566</a> -->
                            </div>
                        </div>
                        <div class="branch-detail branch-mail">
                            <font-awesome-icon
                                class="icon"
                                :icon="['fas', 'envelope']"
                                size="2x"
                            />
                            <a href="mailto:sales@varit.net">sales@varit.net</a>
                        </div>
                    </div>
                    <div class="branch-map">
                        <figure><img
                                src="@/assets/images/branchmap.png"
                                alt=""
                            /></figure>
                    </div>
                </div>
                <div class="branch">
                    <div class="branch-details">
                        <h3 class="branch-title">Germany</h3>
                        <div class="branch-detail branch-location">
                            <font-awesome-icon
                                class="icon"
                                :icon="['fas', 'map-marker-alt']"
                                size="2x"
                            />
                            <p>DIS INDUSTRIAL SOLUTIONS GMBH Birmingham Str.57 65934 Frankfurt</p>
                        </div>
                        <div class="branch-detail branch-phone">
                            <font-awesome-icon
                                class="icon"
                                :icon="['fas', 'mobile-alt']"
                                size="2x"
                            />
                            <div>
                                <a href="tel:+4917623875322⁩">+4917623875322⁩</a>
                                <!-- <br />
                                <a href="tel:+002246652566">002246652566</a> -->
                            </div>
                        </div>
                        <div class="branch-detail branch-mail">
                            <font-awesome-icon
                                class="icon"
                                :icon="['fas', 'envelope']"
                                size="2x"
                            />
                            <a href="mailto:sales@varit.net">sales@varit.net</a>
                        </div>
                    </div>
                    <div class="branch-map">
                        <figure><img
                                src="@/assets/images/branchmap.png"
                                alt=""
                            /></figure>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {};
</script>
