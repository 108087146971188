<template>
  <div class="page--home">
    <!-- <img alt="Vue logo" src="../assets/images/logo.png" /> -->
    <!-- <HelloWorld msg="Welcome to Your Vue.js App" /> -->
    <!-- <button class="btn is-main">
      {{ $t("headings.home") }}
    </button>
    <font-awesome-icon :icon="['fas', 'ad']" size="3x" /> -->

    <hero :text="$t('misc.homeDescription')">
      <img alt="Vue logo" src="@/assets/images/hero.svg" />
    </hero>
    <whyUs />
    <aboutUs />
    <whatWeDo />
    <!-- <section>
      <div class="container flex justify-between">
        <div v-for="card in workCards" :key="card" class="w-1/3 mx-8">
          <work-card :work-card="card" :is-revert="true" />
        </div>
      </div>
    </section> -->
    <!-- <section>
      <div class="container flex justify-between">
        <div v-for="(card, index) in teamCards" :key="index" class="w-1/3 mx-8">
          <team-card :team-card="card" />
        </div>
      </div>
    </section> -->

    <section class="mt-16 mx-auto bgourwork py-32" v-if="false">
      <div>
        <div class="our-work container container-l mx-auto flex flex-wrap">
          <div
            class="w-10/12 xs:w-8/12 sm:w-1/2 xl:w-1/3 text-center text-4xl 2xs:text-5xl sm:text-6xl font-bold mt-8 mx-auto"
          >
            <h2 class="mb-4">{{ $t("headings.ourWork") }}</h2>
            <figure><img src="@/assets/images/our-work.svg" alt="" /></figure>
          </div>

          <div
            v-for="card in workCards"
            :key="card.id"
            class="w-10/12 sm:w-1/2 xl:w-1/3 px-8 mt-8 mx-auto"
          >
            <work-card :work-card="card" />
          </div>
          <div class="flex items-center mx-auto mt-24">
            <!-- <a href="/work" class="text-secondary-600 text-3xl font-black"
							>For All Work
						</a> -->
            <router-link
              to="/work"
              class="text-secondary-600 text-3xl font-black"
            >
              {{ $t("labels.forAllWork") }}
            </router-link>
            <!-- v-if="($i18n.locale = 'ar')" -->
            <font-awesome-icon
              class="icon mx-2"
              :icon="
                $i18n.locale === 'ar'
                  ? ['fas', 'arrow-left']
                  : ['fas', 'arrow-right']
              "
              size="2x"
            />
          </div>
        </div>
      </div>
    </section>

    <section v-if="false">
      <div class="container container-l mx-auto">
        <div class="our-team flex flex-wrap items-center justify-center">
          <div
            class="w-10/12 xs:w-8/12 sm:w-1/2 xl:w-1/3 text-center text-4xl 2xs:text-5xl sm:text-6xl font-bold mt-8 mx-auto"
          >
            <h2 class="mb-4">{{ $t("headings.ourTeam") }}</h2>
            <figure class="mx-auto our-team-grafic">
              <img
                class="w-full h-full"
                src="@/assets/images/our-work.svg"
                alt=""
              />
            </figure>
          </div>

          <div
            v-for="cardd in teamCards"
            :key="cardd.id"
            class="our-team-card-container w-10/12 sm:w-1/2 xl:w-1/3 px-8 mt-8 mx-auto"
          >
            <team-card :team-card="cardd" />
          </div>
        </div>
      </div>
    </section>
    <!-- <section>
      <div>
        <team-section />
      </div>
    </section> -->
    <section class="bg-blog" v-if="false">
      <div>
        <blog-section
          :title="$t('headings.blog')"
          with-desc="true"
          :desc="$t('misc.blogSectionDescription')"
          :aticles="blogArticles"
        />
      </div>
    </section>
    <section>
      <partners />
    </section>
    <section>
      <branches />
      <social />
    </section>
  </div>
</template>

<script>
import Hero from "@/components/Hero.vue";
import WhyUs from "@/components/WhyUs.vue";
import WorkCard from "@/components/WorkCard";
import TeamCard from "../components/TeamCard.vue";
// import TeamSection from "../components/TeamSection.vue";
import BlogSection from "../components/BlogSection.vue";
import Partners from "../components/Partners.vue";

import WhatWeDo from "@/components/WhatWeDo.vue";
import Branches from "../components/Branches.vue";
import AboutUs from "@/components/AboutUs.vue";

import Social from "@/components/Social.vue";

// import TeamCard from "@/components/TeamCard";
// import WorkCard from '../components/itemscomponents/WorkCard.vue';

export default {
  name: "Home",
  components: {
    // HelloWorld,
    AboutUs,
    Hero,
    WhyUs,
    WhatWeDo,
    Social,

    // TeamCard,
    WorkCard,
    TeamCard,
    // TeamSection,
    BlogSection,
    Partners,
    Branches
  },
  data() {
    return {
      workCards: [
        { name: "card1", path: "@/assets/images/fastanswer1.png" },
        { name: "card1", path: "@/assets/images/fastanswer1.png" },
        { name: "card1", path: "@/assets/images/fastanswer1.png" },
        { name: "card1", path: "@/assets/images/fastanswer1.png" },
        { name: "card1", path: "@/assets/images/fastanswer1.png" }
      ],
      teamCards: [
        {
          name: "card1",
          title: "web developer",
          image: "@/assets/images/fastanswer1.png"
        },
        {
          name: "card1",
          title: "web developer",
          image: "@/assets/images/fastanswer1.png"
        },
        {
          name: "card1",
          title: "web developer",
          image: "@/assets/images/work-card-1.png"
        },
        {
          name: "card1",
          title: "web developer",
          image: "@/assets/images/fastanswer1.png"
        },
        {
          name: "card1",
          title: "web developer",
          image: "@/assets/images/fastanswer1.png"
        },
        {
          name: "card1",
          title: "web developer",
          image: "@/assets/images/fastanswer1.png"
        },
        {
          name: "card1",
          title: "web developer",
          image: "@/assets/images/fastanswer1.png"
        }
      ],
      blogArticles: [
        {
          id: 2,
          text: "related article test 2",
          media: [{ path: "@/assets/images/work-card-1.png" }],
          slug: "2467879"
        },
        {
          id: 3,
          text: "related article test 3",
          media: [{ path: "@/assets/images/work-card-1.png" }],
          slug: "243379"
        },
        {
          id: 4,
          text: "related article test 4",
          media: [{ path: "@/assets/images/work-card-1.png" }],
          slug: "245479"
        },
        {
          id: 5,
          text: "related article test 5",
          media: [{ path: "@/assets/images/work-card-1.png" }],
          slug: "245579"
        }
      ]
    };
  }
};
</script>
